import React, {useRef, useState} from 'react';

const Form = ({testdata}) => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleFr, setTitleFr] = useState('');
    const [messageEn, setMessageEn] = useState('');
    const [messageFr, setMessageFr] = useState('');
    const [toUsers, setToUsers] = useState('');
    const refCheckbox = useRef();
    const submitRef = useRef()
    const consoleRef = useRef()
    const [testingData, setTestingData] = useState(JSON.stringify(testdata, undefined, 2))
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            payload: {"type":"Campaign"}
        }
        if (!titleEn || !messageEn || !titleFr || !messageFr) {
            consoleRef.current.value = "Enter complete data";
            return
        }
        data.messageEn = {
            title: titleEn,
            body: messageEn
        }
        data.messageFr = {
            title: titleFr,
            body: messageFr
        }

        if (toUsers !== "") {
             data.toUsers = toUsers;
        }

        if (refCheckbox.current.checked) {
            try {
                data.testingData = JSON.parse(testingData)
            } catch (e) {
                consoleRef.current.value = "unable to parse testing data, check json"
                return;
            }
        }

        fetch('/api/sendnotification?app=akhyar', {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                response.json().then(res => {
                    console.log(res);
                    consoleRef.current.value = JSON.stringify(res, undefined, 2)
                })
            })
            .catch((error) => {
                console.log(error);
                consoleRef.current.value = error.message
            });
    };

    return (
        <div className="full-height">
            <div className="mt-4"><h3>Akhyar Notificaiton Sender Utility</h3></div>
            <form onSubmit={handleSubmit} style={{overflowY:"auto", height:"calc(100% - 230px)", overflowX:"hidden"}}>
                <p>&nbsp;</p>
                <h5>Api Credentials</h5>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Username</label>
                            <input
                                className="form-control input-sm"
                                type="text"
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="last">Password</label>
                            <input
                                className="form-control"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6"><h5>English</h5></div>

                    <div className="col-md-6" style={{borderLeft: "2px solid #00f"}}><h5>French</h5></div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={titleEn}
                                onChange={(e) => setTitleEn(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="last">Message</label>
                            <textarea
                                className="form-control"
                                value={messageEn}
                                onChange={(e) => setMessageEn(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6" style={{borderLeft: "2px solid #00f"}}>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={titleFr}
                                onChange={(e) => setTitleFr(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="last">Message</label>
                            <textarea
                                className="form-control"
                                value={messageFr}
                                onChange={(e) => setMessageFr(e.target.value)}
                            />
                        </div>
                    </div>
                </div><div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>To users</label>
                        <input
                            type="text"
                            className="form-control"
                            value={toUsers}
                            onChange={(e) => setToUsers(e.target.value)}
                        />
                    </div>
                </div>
            </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Testing data</label>
                            <textarea
                                id="t1"
                                className="form-control"
                                rows={6}
                                value={testingData}
                                onChange={(e) => setTestingData(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label>
                            <input type="checkbox" ref={refCheckbox} defaultChecked={true}/> Use testing data
                            <div className="alert small p-1 alert-info">Testing data will use the above tokens</div>
                        </label>
                        <br/>
                        <label>
                            <input type="checkbox" onChange={(e) => {
                                submitRef.current.style.display = e.target.checked ? "inline-block" : "none"
                            }}/> Sure to send?
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-success " ref={submitRef}
                                style={{display: "none"}}>Send Notification
                        </button>
                    </div>
                </div>
            </form>
            <div className="console">
                <textarea ref={consoleRef}/>
            </div>
        </div>
    );
};

export default Form;
